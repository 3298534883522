const steps = [
  {
    element: "#add-team-button",
    popover: {
      title: "Add Team",
      description: "Allows you to create a team for your organisation",
      position: "bottom",
    },
  },
  {
    element: "#search-team",
    popover: {
      title: "Search for Team",
      description:
        "Allows you to search for a specific team(s) for your organisation",
      position: "bottom",
    },
  },
  {
    element: "#edit-team-button",
    popover: {
      title: "Add Team",
      description: "Allows you to edit a team for your organisation",
      position: "bottom",
    },
  },
  {
    element: "#remove-team-button",
    popover: {
      title: "Remove Team",
      description: "Allows you to remove a team for your organisation",
      position: "bottom",
    },
  },
];

export default steps;
