const steps = [
  {
    element: "#team-name",
    popover: {
      title: "Team - Name",
      description: "This is where you provide the unique name of this team",
      position: "bottom",
    },
  },
  {
    element: "#team-color",
    popover: {
      title: "Team - color",
      description:
        "This is where you provide a unique color to represent this team",
      position: "right",
    },
  },
  {
    element: "#team-members-select",
    popover: {
      title: "Select Team Members",
      description: "This is where you select people to be a part of this team",
      position: "top",
    },
  },
  {
    element: "#team-members-table",
    popover: {
      title: "Team Members Display",
      description: "This is where you select people to be a part of this team",
      position: "top",
    },
  },
];

export default steps;
