<template>
    <div style="background: var(--v-component-base) !important">
        <v-divider></v-divider>
        <v-subheader style="font-size: 16px"><v-icon class="mr-2" color="secondary" >groups</v-icon> Teams 
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialog = true, item = { modalType: 'Add', userTeams: [] }" icon><v-icon>add_circle_outline</v-icon></v-btn>
                <v-btn @click="infoStartGuide" icon><v-icon>help</v-icon></v-btn>
            </v-subheader>
            <v-text-field label="Search" prepend-inner-icon="search" v-model="search" outlined dense clearable hide-details></v-text-field>
        <v-list dense subheader class="my-0 py-0" style="max-height: 28vh; overflow-y: auto">
            <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
            <v-list-item v-for="team in filterTeams" :key="team.id" @click="editItem(team)" style="height: 45px">
            <v-list-item-action>
                <v-icon size="32" :color="team.colour">fiber_manual_record</v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>
                    {{ team.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    <v-icon small color="grey">person</v-icon> {{ team.userTeams.length }}
                </v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!loading && filterTeams.length ==0">
            <v-list-item-content class="text-center">
                <span style="color: grey">No teams found.</span>
            </v-list-item-content>
            </v-list-item>
            </v-list>
        <!-- <v-row justify="end" class="mb-2">
            <v-col cols="12" sm="12" md="8" lg="6">
                <el-input id="search-team" suffix-icon="el-icon-search" v-model="search" clearable placeholder="Search">
                    <template slot="prepend">
                        <el-button id="add-team-button" @click="">
                            <i class="el-icon-plus"></i></el-button>
                    </template>
                    <template slot="append">
                        <el-button @click="infoStartGuide">
                            <v-icon>help</v-icon>
                        </el-button>
                    </template>
                </el-input>
            </v-col>
        </v-row>
        <v-data-table :items="teams" :loading="loading" :headers="headers">
            <template v-slot:[`item.action`]="{ item }">

                <v-btn id="edit-team-button" small icon @click="editItem(item)">
                    <v-icon>edit</v-icon>
                </v-btn>
                <v-btn id="remove-team-button" small icon @click="removeItem(item)">
                    <v-icon color="red">remove_circle_outline</v-icon>
                </v-btn>
            </template>
            <template v-slot:[`item.name`]="{ item }">
                <v-avatar size="18" class="mr-2" :style="{ background: item.colour }"></v-avatar>
                <span>{{ item.name }}</span>
            </template>
            <template v-slot:[`item.userTeams`]="{ item }">
                <v-chip>{{ item.userTeams.length }}</v-chip>
            </template>
        </v-data-table> -->

        <!-- Add/Edit Role dialog -->
        <v-dialog v-model="dialog" width="1500px" persistent :fullscreen="$vuetify.breakpoint.mobile">
        <v-card flat>
            <v-toolbar flat color="transparent">
                <v-toolbar-title>
                    Manage Team
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="secondary" icon @click="saveTeam()" :loading="savingTeam" :disabled="!item.name" ><v-icon>save</v-icon></v-btn>
                <v-btn color="red" icon @click="removeItem(item)" :loading="deletingTeam" v-if="item.id"><v-icon>delete</v-icon></v-btn>
                <v-btn text @click="dialog=false, item={}">X</v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="4">
                        <v-subheader style="font-size: 16px" > <v-icon color="secondary" class="mr-2"></v-icon> Details</v-subheader>
                        <v-divider></v-divider>
                        <v-text-field label="Name" v-model="item.name" outlined dense clearable></v-text-field>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Colour
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <el-color-picker v-model="item.colour" id="team-color"></el-color-picker>
                            </v-list-item-action>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-subheader style="font-size: 16px" > <v-icon color="secondary" class="mr-2"></v-icon> Team Members</v-subheader>
                        <v-divider></v-divider>
                        <v-text-field placeholder="Search" prepend-inner-icon="search" v-model="searchUsers" outlined
                                dense clearable> </v-text-field>
                        <v-list dense subheader style="max-height: 60vh; overflow-y: auto">
                                <v-list-item v-if="(item.userTeams && item.userTeams.length == 0)">
                                    <v-list-item-content>
                                        <v-list-item-title style="color: grey">
                                            No linked users
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-for="(user, index) in filteredUsers" :key="index">
                                    <v-list-item-action>
                                        <v-avatar :color="user.organisationUser.user.avatar ? 'white' : 'secondary'" size="36">
                                            <v-img v-if="user.organisationUser.user.avatar" referrerpolicy="no-referrer"
                                                :src="user.organisationUser.user.avatar" contain></v-img>
                                            <h2 v-else style="color: white; font-weight: normal">{{ user.organisationUser.user.firstname.charAt(0) }}</h2>
                                        </v-avatar>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ user.organisationUser.user.firstname }} {{ user.organisationUser.user.surname }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action :key="loadingUsersKey">
                                        <v-btn color="red" :loading="loadingUsers.includes(index)" icon @click="removeUserItem(user, index)"><v-icon>block</v-icon></v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                    </v-col>
                    <v-col cols="12" sm="4" :key="loadingUsersKey">
                        <v-subheader style="font-size: 16px" > <v-icon color="secondary" class="mr-2"></v-icon> Remaining Users</v-subheader>
                        <v-divider></v-divider>
                        <v-text-field placeholder="Search" prepend-inner-icon="search" v-model="searchRemaining" outlined
                                dense clearable> </v-text-field>
                        <v-list dense subheader style="max-height: 60vh; overflow-y: auto">
                                <v-list-item v-if="remainingUsers.length == 0">
                                    <v-list-item-content>
                                        <v-list-item-title style="color: grey">
                                            No remaining users
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-for="(user, index) in remainingUsers" :key="index">
                                    <v-list-item-action>
                                        <v-avatar :color="user.user.avatar ? 'white' : 'secondary'" size="36">
                                            <v-img v-if="user.user.avatar" referrerpolicy="no-referrer"
                                                :src="user.user.avatar" contain></v-img>
                                            <h2 v-else style="color: white; font-weight: normal">{{ user.user.firstname.charAt(0) }}</h2>
                                        </v-avatar>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ user.user.firstname }} {{ user.user.surname }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <!-- <v-btn color="red" icon @click="removeUser(user, index)"><v-icon>block</v-icon></v-btn> -->
                                        <v-btn color="blue" v-if="!user.added" :loading="userLoading.includes(user.userId)" icon @click="addUser(user)"><v-icon>add</v-icon></v-btn>
                                        <v-btn color="red" v-else icon @click="removeUser(user.data, index)"><v-icon>block</v-icon></v-btn>

                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>
        <!-- <el-dialog :title="item.modalType + ' Team'" :visible.sync="dialog" :fullscreen="$vuetify.breakpoint.mobile"
            width="850px" :closed="() => { item = {}, dialog = false }">
            <el-form class="modal-form" :model="item" ref="form" @submit.native.prevent="saveTeam">
                <el-form-item prop="name" id="team-name">
                    <el-input v-model="item.name" placeholder="Name" clearable>
                        <v-icon v-if="item.name" slot="suffix" class="mr-1" small color="green">check</v-icon>
                        <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                    </el-input>
                </el-form-item>
                <el-form-item prop="name" label="Colour">
                    

                </el-form-item>
                <el-form-item prop="members">
                    <v-row justify="center">
                        <v-col cols="12" class="text-center pb-0 mb-0">
                            <h3>Members</h3>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="4" class="text-center pt-0 mt-0">
                            <v-autocomplete id="team-members-select" :items="filterOrgUsers" item-value="id" dense outlined
                                rounded label="Add Member" item-text="user.firstname" v-model="selectedUser" return-object>
                                <template v-slot:item="data">
                                    <v-list-item @click="addUser(data.item)">
                                        <v-list-item-content>
                                            <v-list-item-title style="font-size: 12px">
                                                {{ data.item.user.firstname }} {{ data.item.user.surname }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 12px">
                                                {{ data.item.position }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-data-table id="team-members-table" :headers="userHeaders" :items="item.userTeams" item-key="name"
                        class="elevation-1">
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn small icon @click="removeUserItem(item)">
                                <v-icon color="red">remove_circle_outline</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </el-form-item>

                <el-form-item style="text-align: center">
                    <el-button v-if="item.modalType == 'Add'" :loading="loading" style="min-width: 40%" type="primary"
                        :disabled="!item.name" native-type="submit" block> {{ !loading ? 'Submit' : '' }}</el-button>
                    <el-button v-else :loading="loading" style="min-width: 40%" type="primary" :disabled="!item.name"
                        native-type="submit" block> {{ !loading ? 'Save' : '' }}</el-button>
                </el-form-item>
            </el-form>
            <v-btn id="info-guide" @click="dialogStartGuide" fab height="4vh" width="4vh"
                style="position: absolute; z-index: 5; right: 1vw; top: 1vh">
                <v-icon>help</v-icon>
            </v-btn>

            <v-dialog v-model="dialogStart" max-width="31vw">
                <v-card>
                    <v-btn @click="dialogStartGuide" icon
                        style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon color="primary"
                            size="30">close</v-icon></v-btn>
                    <v-card-title class="d-flex justify-center">
                        FRIKO Welcome to Edit/Add Team!
                    </v-card-title>
                    <v-card-text>
                        <span>
                            <p>FRIKO</p>
                            <p>FRIKO
                                <br>• Map out routes for transporters
                                <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
                                <br>• Calculate accurate waiting times at loading points
                                <br>• Calculate distances for billing purposes
                                <br>• Pin-point locations for the purpose of reporting and analysis
                                <br>• Map out farms that aren't found on Google Maps
                            </p>
                            If you would like learn how to load POI's into the system, click the button below to start the
                            tutorial.
                        </span>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center">
                        <v-btn @click="startDialogGuide" color="edit" class="mb-3">Start!</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </el-dialog> -->
        <v-dialog v-model="infoStart" max-width="31vw">
            <v-card>
                <v-btn @click="infoStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
                        color="primary" size="30">close</v-icon></v-btn>
                <v-card-title class="d-flex justify-center">
                    FRIKO Welcome to Team!
                </v-card-title>
                <v-card-text>
                    <span>
                        <p>FRIKO</p>
                        <p>FRIKO
                            <br>• Map out routes for transporters
                            <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
                            <br>• Calculate accurate waiting times at loading points
                            <br>• Calculate distances for billing purposes
                            <br>• Pin-point locations for the purpose of reporting and analysis
                            <br>• Map out farms that aren't found on Google Maps
                        </p>
                        If you would like learn how to load POI's into the system, click the button below to start the
                        tutorial.
                    </span>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


    </div>
</template>

<script>
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import teamSteps from './Steps/teamSteps'
import dialogSteps from './Steps/teamDialogSteps'

export default {
    data: () => ({
        deletingTeam: false,
        dialog: false,
        driver: null,
        headers: [{
            text: 'Action',
            align: 'center',
            sortable: false,
            value: 'action'
        },
        {
            text: 'Name',
            value: 'name',
            align: 'center',
        }, {
            text: 'Members',
            value: 'userTeams',
            align: 'center',
        },
        ],
        userHeaders: [{
            text: 'Action',
            align: 'center',
            sortable: false,
            value: 'action'
        },
        {
            text: 'Name',
            value: 'organisationUser.user.firstname',
            align: 'center',
        },
        {
            text: 'Surname',
            value: 'organisationUser.user.surname',
            align: 'center',
        }, {
            text: 'Position',
            value: 'organisationUser.position',
            align: 'center',
        },
        ],
        item: {},
        loading: false,
        loadingUsers: [],
        loadingUsersKey: 2000,
        orgUsers: [],
        savingTeam: false,
        search: '',
        searchUsers: null,
        selectedUser: {},
        searchRemaining: null,
        teams: [],
        userLoading: [],
        infoStart: false,
        dialogStart: false
    }),
    computed: {
        filterOrgUsers() {
            let result = this.orgUsers
            if (this.item && this.item.id) {
                result = result.filter(x => !this.item.userTeams.map(y => y.organisationUserId).includes(x.id))
            }
            return result
        },
        filterTeams(){
            let result = this.teams
            if(this.search){
                result = result.filter(x => x.name.toLowerCase().includes(this.search.toLowerCase()))
            }
            return result
        },
        filteredUsers() {
            let result = this.item.userTeams ?? []
            if (this.searchUsers) {
                result = result.filter(x =>
                    (x.organisationUser.user.firstname.toLowerCase()).includes(this.searchUsers.toLowerCase()) ||
                    (x.organisationUser.user.surname.toLowerCase()).includes(this.searchUsers.toLowerCase())
                )
            }
            return result
        },
        remainingUsers(){
            let result = []
            if(this.item && this.item.userTeams){
                result = [...new Set(this.item.userTeams.map(x=>x.organisationUser.user.id))]
                result = this.orgUsers.filter(x=>!result.includes(x.userId))
            }  
            result.sort((a,b)=>a.user.firstname.localeCompare(b.user.firstname))
            if(this.searchRemaining){
                result = result.filter(x => 
                    (x.user.firstname.toLowerCase()).includes(this.searchRemaining.toLowerCase()) ||
                    (x.user.surname.toLowerCase()).includes(this.searchRemaining.toLowerCase()))
            }
            return result
        }
    },
    created() {
        this.load()
    },
    async mounted() {
        this.driver = new Driver({
            animate: false
        })
    },
    methods: {
        async addUser(userItem) {
            this.userLoading.push(userItem.userId)
            this.userKey ++
            if (this.item.id) {
                let user = await this.$API.createTeamUser({
                    teamId: this.item.id,
                    organisationUserId: userItem.id
                })
                user.organisationUser = userItem
                this.item.userTeams.push(user)
            } else {
                this.item.userTeams.push({
                    organisationUser: userItem
                })
            }
            this.userLoading.splice(this.userLoading.indexOf(userItem.userId), 1)
            this.userKey ++
        },
        // async addUser(user){
        //     this.userLoading.push(user.userId)
        //     if(this.roleItem.id){
        //         let result = await this.$API.createUserRole({
        //         batch: [{userId: user.userId, roleId: this.roleItem.id}]
        //     })
        //     user.added = true
        //     let data = result[0]
        //     data.user = user.user
        //     this.roleItem.userRoles.push(data)
        //     user.data = data
        //     } else {
        //         user.added = true
        //     let data = {
        //         userId: user.userId,
        //         roleId: null,
        //         user: user.user
        //     }
        //     this.roleItem.userRoles.push(data)
        //     user.data = data
        //     }
        //     this.userLoading = this.userLoading.filter(x=>x!=user.userId)
        //     this.userKey ++
        // },
        editItem(item) {
            this.item = item
            console.log(item)
            this.item.modalType = 'Edit'
            this.dialog = true
        },
        async getOrganisationUsers() {
            this.orgUsers = await this.$API.getOrganisationUsers()
        },
        async getTeams() {
            this.loading = true
            this.teams = await this.$API.getTeams()
            this.loading = false
        },
        async load() {
            this.getTeams()
            this.getOrganisationUsers()
        },

        removeItem(item) {
            this.$confirm('Are you sure you want to delete this team?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                this.deletingTeam = true
                await this.$API.updateTeam({
                    id: item.id,
                    isActive: false,
                    isDeleted: true
                })
                this.teams.splice(this.teams.findIndex(x=>x.id == item.id), 1)
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
                this.dialog = false
                this.item = {}
                this.deletingTeam = false
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
                this.deletingTeam = false
            });
        },
        removeUserItem(user, index) {
            this.$confirm(`Are you sure you want to remove ${user.organisationUser.user.firstname} ${user.organisationUser.user.surname} from ${this.item.name}?`, 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                this.loadingUsers.push(index)
                this.loadingUsersKey ++
                await this.$API.updateTeamUser({
                    id: user.id,
                    isActive: false,
                    isDeleted: true
                })
                this.item.userTeams.splice(index, 1)
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
                this.loadingUsers.splice(this.loadingUsers.indexOf(index), 1)
                this.loadingUsersKey ++
            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: 'Delete canceled'
                // });
                this.loadingUsers.splice(this.loadingUsers.indexOf(index), 1)
                this.loadingUsersKey ++

            });
        },
        async saveTeam() {
            this.savingTeam = true
            if (this.item.id) {
                await this.$API.updateTeam(this.item)

            } else {
                let result = await this.$API.createTeam(this.item)
                let userTeams = this.item.userTeams.map(x => ({
                    teamId: result.id,
                    organisationUserId: x.organisationUser.id
                }))
                await this.$API.createTeamUser({
                    batch: userTeams
                })
                this.getTeams()
            }
            this.dialog = false
            this.item = {}
            this.savingTeam = false
        },
        infoStartGuide() {
            if (this.infoStart) {
                this.infoStart = false;
            } else {
                this.infoStart = true;
            }
        },
        startGuide(e) {
            this.infoStart = false
            e.stopPropagation();
            this.driver.defineSteps(teamSteps)
            this.driver.start()
        },
        dialogStartGuide() {
            if (this.dialogStart) {
                this.dialogStart = false;
            } else {
                this.dialogStart = true;
            }
        },
        startDialogGuide(e) {
            this.dialogStart = false
            e.stopPropagation();
            this.driver.defineSteps(dialogSteps)
            this.driver.start()
        },
    }
}
</script>
